import { render, staticRenderFns } from "./upload.vue?vue&type=template&id=074dbee0&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/invoiceapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('074dbee0')) {
      api.createRecord('074dbee0', component.options)
    } else {
      api.reload('074dbee0', component.options)
    }
    module.hot.accept("./upload.vue?vue&type=template&id=074dbee0&", function () {
      api.rerender('074dbee0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/icon/upload.vue"
export default component.exports